import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Badge } from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import { accountService } from "../../services/account.service";
import { setModal } from "../../store/actions";

const Register = (props) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.company);
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [registerTypes, setRegisterTypes] = useState([]);
    const [enrollment, setEnrollment] = useState({ show: false, text: "" });
    const [dateNow, setDateNow] = useState();

    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const registerTypeRef = useRef();

    useEffect(() => {
        var today = new Date(),
            date = today.getFullYear() + '-' + ((today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '-' + (today.getDate() - 1);
        setDateNow(date);
        //Add reCaptcha
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js?render=" +
            process.env.REACT_APP_PUBLIC_KEY;
        //script.addEventListener("load", handleLoaded)
        document.body.appendChild(script);
    }, []);

    const handleSearchCompany = (query) => {
        setIsLoading(true);
        accountService.getCompanyCatalog(query)
            .then((result) => {
                if (result.Status) {
                    setCompanies(result.Model);
                    setIsLoading(false);
                }
            });
    };

    const handleChangeCompany = (selected) => {
        const value = selected.length > 0 ? selected : undefined;
        if (value == undefined) {
            setRegisterTypes([]);
            return;
        }

        accountService.getRegisterTypeCatalog(value[0].Value)
            .then((result) => {
                if (result.Status) {
                    setRegisterTypes(result.Model);
                    let registerTypes = result.Model[0];
                    setEnrollment({
                        show: !(
                            registerTypes.Value == 1 ||
                            registerTypes.Value == 8 ||
                            registerTypes.Value == 9
                        ),
                        label: registerTypes.Label,
                    });
                } else
                    setEnrollment({ show: false, label: "" });
            });
        registerTypeRef.current.focus();
        return value;
    };

    const handleChangeRegisterType = (e) => {
        const filter = registerTypes.filter((f) => f.Value == e.target.value);
        if (filter.length > 0) {
            const value = e.target.value;
            const text = e.target[e.target.selectedIndex].innerText;
            setEnrollment({ show: !(value == 1 || value == 8 || value == 9), text: text });
        } else {
            swal({ text: "Este valor seleccionado no corresponde", icon: "warning" })
        }
        return e;
    }

    const onSubmit = (data) => {
        if (data.IAgree) {
            window.grecaptcha.ready((_) => {
                window.grecaptcha
                    .execute(process.env.REACT_APP_PUBLIC_KEY, { action: "submit" })
                    .then((captchaResponse) => {
                        data.CaptchaResponse = captchaResponse;
                        data.Company = data.Company[0].Value;
                        accountService.register(data)
                            .then((result) => {
                                if (result.Status) {
                                    reset();
                                    dispatch(setModal());
                                    swal({ text: result.Message, icon: "success" });
                                } else
                                    swal({ text: result.Message, icon: "error" });
                            });
                    });
            })
        } else
            swal({
                text: "Debe aceptar los términos y condiciones",
                icon: "warning",
            });
    };

    return (
        <div className="content_logeo">
            <div className="pt-3">
                <h5 className="ttls text-center mb-2">Registro nuevo</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="FirstName">Nombre(s)</label>
                                <input type="text" className="form-control" {...register("FirstName", { required: true })} />
                                {errors.FirstName && <Badge variant="danger">Nombre requerido</Badge>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="Surnames">Apellidos</label>
                                <input type="text" className="form-control" {...register("Surnames", { required: true })} />
                                {errors.Surnames && <Badge variant="danger">Apellidos requeridos</Badge>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="Birthday"><i className="fas fa-calendar"></i> Fecha de nacimiento</label>
                                <input type="date" className="form-control" {...register("Birthday", { required: true })} max={dateNow} />
                                {errors.Birthday && <Badge variant="danger">Fecha de nacimiento es requerido</Badge>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-1"><label htmlFor="Gender">Sexo</label></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id='rdM' className="custom-control-input" value="M" {...register("Gender", { required: true })} />
                                <label className="custom-control-label" for="rdM" ><small>Masculino</small></label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id='rdF' className="custom-control-input" value="F" {...register("Gender", { required: true })} />
                                <label className="custom-control-label" for="rdF" ><small>Femenino</small></label>
                            </div>
                            {errors.Gender && <Badge variant="danger">Genero es requerido</Badge>}
                        </div>

                        <div className={`col-sm-6 ${company == "1Publico" ? "hide" : ""}`}>
                            <div className="form-group">
                                <label for="company">Empresa</label>
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name="Company"
                                    render={({ field }) => (<AsyncTypeahead
                                        {...field}
                                        filterBy={() => true}
                                        isLoading={isLoading}
                                        labelKey="Label"
                                        minLength={3}
                                        onSearch={handleSearchCompany}
                                        options={companies}
                                        placeholder="Escribe el nombre..."
                                        renderMenuItemChildren={(option, props) => (
                                            <>
                                                <span>{option.Label}</span>
                                            </>
                                        )}
                                        onChange={(e) => { field.onChange(e); handleChangeCompany(e) }}
                                    />)}
                                />

                                {errors.Company && <Badge variant="danger">Empresa es requerida</Badge>}
                            </div>
                        </div>
                        <div className={`col-sm-6 ${company == "1Publico" ? "hide" : ""}`}>
                            <div className="form-group">
                                <label htmlFor="RegisterType">Tipo de registro</label>
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name="RegisterType"
                                    render={({ field }) => (
                                        <select {...field}
                                            className="form-control"
                                            onChange={(e) => { field.onChange(e); handleChangeRegisterType(e) }}
                                            ref={registerTypeRef}>
                                            <option key={0} value="" >Seleccione...</option>
                                            {
                                                registerTypes.map(item =>
                                                    <option key={item.Value} value={item.Value}>{item.Label}</option>
                                                )
                                            }
                                        </select>
                                    )}
                                />
                                {errors.RegisterType && <Badge variant="danger">Tipo de registro es requerido</Badge>}
                            </div>
                        </div>
                        {
                            enrollment.show ?
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="enrollment">{enrollment.text}</label>
                                        <input type="text" className="form-control" defaultValue={props.enrollment} {...register("enrollment", { required: enrollment.show })} />
                                        {errors.enrollment && <Badge variant="danger">Este campo es requerido</Badge>}
                                    </div>
                                </div>
                                :
                                ""
                        }
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="Email">Correo electrónico</label>
                                <input type="email" className="form-control" {...register("Email", {
                                    required: "Email requerido", pattern: {value: /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email no valido" }
                                })} />
                                {errors.Email && <Badge variant="danger">{errors.Email?.message}</Badge>}
                            </div>
                        </div>
                        {/*<div className="col-sm-12">
                            <div className="form-group">
                                <label for="alternativeEmail">Correo alternativo (opcional)</label>
                                <input type="email" className="form-control" name="AlternativeEmail" id="alternativeEmail" ref={register} />
                            </div>
                        </div>*/}
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="Password">Contraseña</label>
                                <input type="password" className="form-control" {...register("Password", { required: true })} />
                                {errors.Password && <Badge variant="danger">Contraseña requerida</Badge>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="ConfirmPassword">Confirmar contraseña</label>
                                <input type="password" className="form-control" {...register("ConfirmPassword", { required: true })} />
                                {errors.ConfirmPassword && <Badge variant="danger">Contraseña requerida</Badge>}
                            </div>
                        </div>
                    </div>
                    {/*<div
                        className="g-recaptcha"
                        data-sitekey="_reCAPTCHA_site_key_"
                        data-size="invisible"
                    ></div>*/}
                    <p className="text-center">
                        <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id='IAgree' {...register("IAgree", { required: true })} />
                            <label className="custom-control-label" htmlFor="IAgree"><small>Acepto términos y condiciones</small></label>
                            {errors.IAgree && <Badge variant="danger">Requerido</Badge>}
                        </div>
                        <button type="submit" className="btn">
                            Registrarse
                        </button>
                    </p>
                </form>
            </div>
        </div>
    );
};
export default Register;
